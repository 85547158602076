import React from 'react'
import Helpers from '../helpers'
import Layout from '../components/layout'
import Releases from '../components/releases';
import Links from '../components/links';

export default ({ pageContext: { project } }) => {
  const releases = Helpers.getReleases(project.slug);

  return (
    <Layout title={project.name}>
      <h1>
        {project.name}
      </h1>
      <section>
        <h2>
          Releases
        </h2>
        <Releases 
          items={releases}
        />
      </section>
      {project.links && (
        <section>
          <h2>
            Links
          </h2>
          <Links
            items={project.links}
          />
        </section>
      )}
    </Layout>
  )
}
